var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isDataReady)?_c('div',{staticClass:"footer d-flex",class:_vm.topLayoutClass},[(_vm.app)?_c('div',{staticClass:"d-flex shrink",class:_vm.phoneLayout ? 'flex-column' : 'align-center'},[(!_vm.phoneLayout)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" qBittorrent "+_vm._s(_vm.app.version)+" ")])]}}],null,false,3135644960)},[_c('span',[_vm._v(" API version: "+_vm._s(_vm.app.apiVersion)+" ")]),_c('br'),_c('span',[_vm._v(" qb-web version: "+_vm._s(_vm.buildInfo)+" ")])])],1):_vm._e(),(!_vm.phoneLayout)?_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}):_vm._e(),_c('div',{staticClass:"icon-label"},[_c('v-icon',[_vm._v("mdi-sprout")]),_vm._v(" "+_vm._s(_vm.allTorrents.length)+" ["+_vm._s(_vm._f("formatSize")(_vm.totalSize))+"] ")],1),(!_vm.phoneLayout)?_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"icon-label"},on),[_c('v-icon',[_vm._v("mdi-nas")]),_vm._v(" "+_vm._s(_vm._f("formatSize")(_vm.info.free_space_on_disk))+" ")],1)]}}],null,false,3909253775)},[_c('span',[_vm._v(" Queued I/O jobs: "+_vm._s(_vm.info.queued_io_jobs)+" ")]),_c('br'),_c('span',[_vm._v(" Avg queue time: "+_vm._s(_vm.info.average_time_queue)+" ms ")])]),(!_vm.phoneLayout)?_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}):_vm._e(),(!_vm.phoneLayout)?_c('div',{staticClass:"icon-label"},[_c('v-icon',{staticClass:"icon-upload-download"},[_vm._v(" mdi-swap-vertical-bold ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("formatSize")(_vm.info.alltime_dl))+"/"+_vm._s(_vm._f("formatSize")(_vm.info.alltime_ul))+" ")])],1):_vm._e()],1):_vm._e(),(_vm.info)?_c('div',{staticClass:"d-flex shrink",class:_vm.phoneLayout ? 'flex-column' : 'align-center'},[(!_vm.phoneLayout)?_c('div',{staticClass:"icon-label"},[_c('v-icon',[_vm._v("mdi-lan")]),_vm._v(" "+_vm._s(_vm.$t('label.dht_nodes', _vm.info.dht_nodes))+" ")],1):_vm._e(),(!_vm.phoneLayout)?_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}):_vm._e(),_c('div',{staticClass:"icon-label"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":_vm._f("connectionIconColor")(_vm.info.connection_status)}},on),[_vm._v(" mdi-"+_vm._s(_vm._f("connectionIcon")(_vm.info.connection_status))+" ")]),(_vm.phoneLayout)?_c('span',[_vm._v(" Network "+_vm._s(_vm.info.connection_status)+" ")]):_vm._e()]}}],null,false,452698304)},[_c('span',[_vm._v(" Network "+_vm._s(_vm.info.connection_status)+" ")])])],1),(!_vm.phoneLayout)?_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}):_vm._e(),_c('div',{staticClass:"icon-label"},[(_vm.phoneLayout)?_c('v-switch',{staticClass:"mt-0 pt-0 speed-switch",attrs:{"hide-details":"","value":_vm.speedLimited,"label":"Alternative speed limits"},on:{"change":_vm.toggleSpeedLimitsMode},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',_vm._b({},'v-icon',_vm.speedModeBind,false),[_vm._v(" mdi-speedometer ")])]},proxy:true}],null,false,2672072414)}):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":_vm.toggleSpeedLimitsMode}},'v-icon',_vm.speedModeBind,false),on),[_vm._v(" mdi-speedometer ")])]}}],null,false,3284983233)},[_c('span',[_vm._v(" Alternative speed limits "+_vm._s(_vm.speedLimited ? 'enabled' : 'disabled')+" ")])])],1),(!_vm.phoneLayout)?_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}):_vm._e(),_c('div',{staticClass:"icon-label"},[_c('v-icon',{attrs:{"color":_vm.info.dl_info_speed > 0 ? 'success' : null}},[_vm._v(" mdi-download ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("formatSize")(_vm.info.dl_info_speed))+"/s "),(_vm.info.dl_rate_limit)?[_vm._v(" ("+_vm._s(_vm._f("formatSize")(_vm.info.dl_rate_limit))+"/s) ")]:_vm._e(),(!_vm.phoneLayout)?[_vm._v(" ["+_vm._s(_vm._f("formatSize")(_vm.info.dl_info_data))+"] ")]:_vm._e()],2)],1),(!_vm.phoneLayout)?_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}):_vm._e(),_c('div',{staticClass:"icon-label"},[_c('v-icon',{attrs:{"color":_vm.info.up_info_speed > 0 ? 'warning' : null}},[_vm._v(" mdi-upload ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("formatSize")(_vm.info.up_info_speed))+"/s "),(_vm.info.up_rate_limit)?[_vm._v(" ("+_vm._s(_vm._f("formatSize")(_vm.info.up_rate_limit))+"/s) ")]:_vm._e(),(!_vm.phoneLayout)?[_vm._v(" ["+_vm._s(_vm._f("formatSize")(_vm.info.up_info_data))+"] ")]:_vm._e()],2)],1)],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }